import React from 'react'

import Layout from "../components/Layout"
import SEO from "../components/seo"

import {
  HeaderWrapper,
  HeaderTitle,
  HeaderDescription,
  HeaderTags,
  TagSpan,
  TagDetails,
  CourseWrapper,
  CourseSloganWrapper,
  CourseSloganTitle,
  CourseSloganDescription,
  CourseImage,
  RedesSociaisUl,
  RedesSociaisLi,
  RedesSociaisLink,
  RedesSociaisIcon,
  AppLink,
} from "../components/Courses/styles"
import links from "../components/SocialLinks/content"
import Icons from "../components/SocialLinks/Icons"

const CursosPage = () => (
  <Layout>
    <SEO
      title="Cursos"
      description="Cursos para backend, frontend e fullstack developer - Node.js,
      Typescript, NestJS, ReactJS, React Native, MongoDB, Mysql, Postgres, TypeORM, Prisma ORM,
      Docker, Git, Github, WSL, Redis."
    />

    {/*Cabecalho da pagina de cursos*/}

    <HeaderWrapper>
      <HeaderTitle>
        Cursos direto ao ponto
      </HeaderTitle>
      <HeaderDescription>
        Cursos práticos, direto ao ponto, alinhados com as práticas
        atuais de mercado, com foco no aprendizado otimizado e com
        soluções aplicáveis em projetos do mundo real.
      </HeaderDescription>
      <HeaderTags>
        <TagSpan>
          + 14 mil
        </TagSpan>
        <TagDetails>
          Devs e alunos diretamente impactados pelos cursos descritos abaixo. Aproveite para consumir&nbsp;
            <AppLink href="https://letsgoahead.com.br/" target="_blank" rel="noopener noreferrer">
              os cursos gratuitos na plataforma online.
            </AppLink>
        </TagDetails>
      </HeaderTags>
    </HeaderWrapper>

    {/*Curso NestJs com GraphQL*/}

    <CourseWrapper
      href="https://www.conexnetworks.com.br"
      target="_blank"
      rel="noopener noreferrer"
    >
      <CourseImage
        src="/assets/img/cursos/nestjs-graphql.png"
        alt="Hands On Course: API GraphQL com NestJs, TypeGraphQL, Apollo Server e Prisma ORM."
      />

      <CourseSloganWrapper>
        <CourseSloganTitle>
          Hands On Course: API GraphQL com NestJs, TypeGraphQL, Apollo Server e Prisma ORM
        </CourseSloganTitle>
        <CourseSloganDescription>
          Curso prático para criação de API GraphQL com NestJS, TypeGraphQL, Apollo Server e Prisma ORM, aplicando os principais recursos do GraphQL tais como: Scalar Types, Object Types, Resolvers, Queries, Mutations, etc.
        </CourseSloganDescription>
      </CourseSloganWrapper>
    </CourseWrapper>

    {/*Curso NodeJs Avançado com Clean Architecture, NestJs e Typescript*/}

    <CourseWrapper
      href="https://pay.kiwify.com.br/msEVbE4"
      target="_blank"
      rel="noopener noreferrer"
    >
      <CourseImage
        src="/assets/img/cursos/nestjs-avancado.png"
        alt="NodeJs Avançado com Clean Architecture, NestJs e Typescript."
      />

      <CourseSloganWrapper>
        <CourseSloganTitle>
          NodeJs Avançado com Clean Architecture, NestJs e Typescript
        </CourseSloganTitle>
        <CourseSloganDescription>
          Criação de projeto prático, direto ao ponto, de uma API Restful Node.js com NestJs e Typescript, com testes automatizados, aplicando conceitos de Domain Driven Design (DDD), Clean Architecture, Design Patterns e princípios SOLID.
        </CourseSloganDescription>
      </CourseSloganWrapper>
    </CourseWrapper>

    {/*Curso API Restful com Node.js*/}

    <CourseWrapper
      href="https://www.udemy.com/course/api-restful-de-vendas/?referralCode=6DDEF85A747CA5CC4135"
      target="_blank"
      rel="noopener noreferrer"
    >
      <CourseImage
        src="/assets/img/cursos/curso-api.png"
        alt="API Restful com Node.js, Typescript, TypeORM, Docker, Postgres, Redis, etc."
      />

      <CourseSloganWrapper>
        <CourseSloganTitle>
          API Restful com Node.js, Typescript, TypeORM, Docker, Postgres, Redis, ...
        </CourseSloganTitle>
        <CourseSloganDescription>
          Curso focado no backend com inúmeras funcionalidades, partindo do zero até conceitos avançados, como testes automatizados com o Jest, design patterns com Domain Driven Design (DDD) e Princípios SOLID, Amazon AWS e muito mais.
        </CourseSloganDescription>
      </CourseSloganWrapper>
    </CourseWrapper>

    {/*Curso NestJS do Zero com TypeORM, Prisma e Swagger*/}

    <CourseWrapper
      href="https://pay.kiwify.com.br/8EVYQQT"
      target="_blank"
      rel="noopener noreferrer"
    >
      <CourseImage
        src="/assets/img/cursos/curso-nest.png"
        alt="NestJS do Zero com TypeORM, Prisma ORM e Swagger."
      />

      <CourseSloganWrapper>
        <CourseSloganTitle>
          NestJS do Zero com TypeORM, Mongoose, Prisma ORM e Swagger
        </CourseSloganTitle>
        <CourseSloganDescription>
          Esse curso introduz os principais pontos do NestJS para criação de projetos de backend Node.js, Javascript e Typescript, incluindo testes automatizados, TypeORM, Prisma ORM e criação de documentação com Swagger.
        </CourseSloganDescription>
      </CourseSloganWrapper>
    </CourseWrapper>

    {/*Curso básico de Node.js e Typescript*/}

    <CourseWrapper
      href="https://pay.kiwify.com.br/vadjVdx"
      target="_blank"
      rel="noopener noreferrer"
    >
      <CourseImage
        src="/assets/img/cursos/curso-node.png"
        alt="Iniciando no desenvolvimento de API Node.js com Typescript."
      />

      <CourseSloganWrapper>
        <CourseSloganTitle>
          Iniciando no desenvolvimento de API Node.js com Typescript
        </CourseSloganTitle>
        <CourseSloganDescription>
          Estudaremos os principais conceitos para criação APIs Restful com NodeJS e Typescript, alinhados com projetos práticos.
        </CourseSloganDescription>
      </CourseSloganWrapper>
    </CourseWrapper>

    {/*Curso básico e gratuito de React*/}

    <CourseWrapper
      href="https://www.youtube.com/playlist?list=PLE0DHiXlN_qpm0nMlvcVxG_O580IXmeRU"
      target="_blank"
      rel="noopener noreferrer"
    >
      <CourseImage
        src="/assets/img/cursos/curso-react.png"
        alt="Curso básico gratuito de React e Typescript."
      />

      <CourseSloganWrapper>
        <CourseSloganTitle>
          Curso básico gratuito de React e Typescript
        </CourseSloganTitle>
        <CourseSloganDescription>
          O objetivo deste mini curso é dar o ponta pé inicial nos conceitos e recursos do ReactJS, para aqueles que ainda não conhecem essa biblioteca, iniciantes em desenvolvimento de aplicações web com foco no frontend.
        </CourseSloganDescription>
      </CourseSloganWrapper>
    </CourseWrapper>

    <RedesSociaisUl>
      {links.map((link, i) => {
        const Icon = Icons[link.label]

        return (
          <RedesSociaisLi key={i}>
            <RedesSociaisLink
              href={link.url} title={link.label}
              target="_blank"
              rel="noopener noreferrer"
            >
              <RedesSociaisIcon>
                <Icon />
              </RedesSociaisIcon>
            </RedesSociaisLink>
          </RedesSociaisLi>
        )
      })}
    </RedesSociaisUl>
  </Layout>
)

export default CursosPage
